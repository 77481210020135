@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background-color: black;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.big-text{
  /* font-family: Ppneuemontreal, sans-serif; */
  width: 30rem;
  font-size: 4rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.sm-text{
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 30px;
}


a { text-decoration: none;
  color: black; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; opacity: 0.8; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }
/* 
table {
  width: 55rem;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  color: black;
  border-radius: 20px;
} */

th {
  
  text-align: left;
  font-weight: bold;
  padding: 8px;
  background-color: none;
  color: white;
  
}

td {

  color: white;
  padding: 8px;
  background-color: none;
}

tbody {

  background-color: none;
}

hr {
  opacity: 3%;
  height: 1px;
}

tr:nth-child(even) {
  background-color: none;

  border-radius: 20px;
}