@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in-text {
    animation: fadeIn 1.5s ease-in; /* Adjust the duration as needed */
  }